<template>
  <div :class="containerClasses" @click="$emit('click', $event)">
    <div class="new-point__marker">
      <span>+</span>
    </div>
    <div class="new-point__content">
      <div>Добавить точку маршрута</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RouteTimelineNewPoint',
  props: { isFirst: { type: Boolean, default: false } },
  computed: {
    containerClasses() {
      return {
        'new-point__container': true,
        'new-point__container_first': this.isFirst,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.new-point {
  &__container {
    min-height: 60px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s linear;
    cursor: pointer;
    user-select: none;

    &:hover {
      border-radius: 5px;
      background-color: #fff4f4;
      transition: background-color 0.2s linear;
    }

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 35px;
      height: 50%;
      width: 4px;
      background-color: #d40302;
    }

    &_first:before {
      top: 50%;
      height: 0;
    }
  }

  &__marker {
    position: absolute;
    top: 50%;
    left: 17px;
    transform: translate(0, -50%);
    border: 4px #d40302 solid;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      font-size: 2rem;
      line-height: 2rem;
      padding-bottom: 6px;
      font-weight: bold;
    }
  }

  &__content {
    padding: 0 70px;
    height: 100%;
    width: 100%;
    text-align: center;
    color: #d40302;
  }
}
</style>
