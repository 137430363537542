<template>
  <div class="photo">
    <img :src="imagePath" alt="Image" />
    <div class="photo__overlay" @click="onClickMaximize">
      <a class="h5 photo__delete-button" @click="deleteImage">
        <b-icon icon="trash-fill" variant="primary" />
      </a>
      <a class="h3 photo__maximize-button">
        <b-icon icon="search" variant="primary" />
      </a>
    </div>
    <div
      v-if="isMaximized"
      class="photo__maximized"
      :class="{ photo__maximized_active: isMaximized }"
      @click.stop="onClickMaximize"
      @keydown.esc="onClickMaximize"
    >
      <img :src="imagePreviewPath" />
      <a class="h1 photo__maximized__close">
        <b-icon icon="x" variant="white" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'photo',
  props: {
    photo: { type: Object, required: true },
    path: { type: String, default: 'path' },
    previewPath: { type: String, default: 'preview_path' },
  },
  computed: {
    imagePath() {
      return this.photo[this.path];
    },
    imagePreviewPath() {
      return this.photo[this.previewPath];
    },
  },
  data() {
    return {
      isMaximized: false,
    };
  },
  methods: {
    async deleteImage(event = undefined) {
      event?.stopPropagation();

      const userAgreed = await this.$bvModal.msgBoxConfirm(
        'Вы действительно хотите удалить изображение?',
        {
          okTitle: 'Удалить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
        },
      );

      if (!userAgreed) {
        return;
      }

      this.$emit('delete', this.photo);
    },
    onClickMaximize() {
      const newVal = !this.isMaximized;
      this.isMaximized = newVal;
      if (newVal) {
        document.addEventListener('keydown', this.listenForEsc);
      } else {
        document.removeEventListener('keydown', this.listenForEsc);
      }
    },
    listenForEsc(event) {
      if (event.key === 'Escape') {
        this.onClickMaximize();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.photo {
  height: 150px;
  width: 150px;
  min-height: 150px;
  min-width: 150px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  user-select: none;

  &:hover {
    .photo__overlay {
      opacity: 1;
      transition: all 0.2s linear;
    }
  }

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: #ffffffaa;
    z-index: 1;
    transition: all 0.2s linear;
  }

  &__delete-button {
    height: 35px;
    width: 35px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      background-color: white;
      transition: all 0.2s linear;
    }
  }

  &__maximize-button {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__maximized {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: black;
    z-index: 2;

    & img {
      object-fit: contain;
    }

    &__close {
      position: absolute;
      top: -3px;
      right: -3px;
      cursor: pointer;
      padding: 15px 17px;
      background: #00000030;
      border: 3px solid transparent;
      transition: border 0.1s linear;

      &:hover {
        border: 3px solid #ffffff80;
        transition: border 0.1s linear;
      }
    }
  }
}
</style>
