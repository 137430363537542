<template>
  <div
    draggable="true"
    :class="containerClasses"
    @dragstart="onDragStart"
    @dragover.prevent="onDragOver"
    @dragend="onDragEnd"
    @drop="onDrop"
    @click="$emit('edit', point)"
  >
    <div class="point__drag">
      <b-iconstack font-scale="1">
        <b-icon stacked icon="three-dots-vertical" />
        <b-icon stacked icon="three-dots-vertical" shift-h="4" />
      </b-iconstack>
    </div>
    <div class="point__marker">
      <span>{{ prettyIndex }}</span>
    </div>
    <div class="point__content">
      <div>{{ placeTitle }}</div>
    </div>
    <AudioPlayer v-if="audio" minimal :file="audio" class="mr-4" />
    <div class="text-nowrap">
      <b-icon icon="pencil" variant="primary" class="mr-3" @click.stop="$emit('edit', point)" />
      <b-icon icon="trash-fill" variant="primary" class="mr-3" @click="deletePoint" />
    </div>
  </div>
</template>

<script>
import AudioPlayer from '@/components/AudioPlayer.vue';

export default {
  name: 'RouteTimelinePoint',
  components: { AudioPlayer },
  props: {
    point: { type: Object, required: true },
    index: { type: Number, required: true },
  },
  computed: {
    prettyIndex() {
      return this.index + 1;
    },
    audio() {
      return this.point.audio_file || this.point.place.audio_file;
    },
    placeTitle() {
      return this.point?.place?.title || 'unknown';
    },
    containerClasses() {
      return {
        point__container: true,
        point__container_first: this.index === 0,
      };
    },
  },
  methods: {
    async deletePoint(event = undefined) {
      event?.stopPropagation();

      const userAgreed = await this.$bvModal.msgBoxConfirm(
        `Вы действительно хотите удалить точку маршрута: ${this.placeTitle}?`,
        {
          okTitle: 'Удалить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
        },
      );

      if (!userAgreed) {
        return;
      }

      this.$emit('delete', this.point);
    },
    onDragStart(event) {
      event.dataTransfer.setData('text/plain', String(this.point.id));
    },
    onDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';

      document.getElementById('dummy')?.remove();
      const closestBorder = this.getClosestBorder(event.clientY);
      const dummyElement = this.createDummyElement();
      if (closestBorder === 'top') {
        this.$el.parentElement.insertBefore(dummyElement, this.$el);
      } else {
        this.$el.parentElement.insertBefore(dummyElement, this.$el.nextElementSibling);
      }
    },
    getClosestBorder(clientY) {
      const { top, bottom } = this.$el.getBoundingClientRect();
      return clientY - top < bottom - clientY ? 'top' : 'bottom';
    },
    createDummyElement() {
      const dummy = document.createElement('div');
      dummy.id = 'dummy';
      dummy.style.backgroundColor = '#d40302';
      dummy.style.width = '100%';
      dummy.style.height = '5px';

      return dummy;
    },
    onDrop(event) {
      const movedPointId = Number(event.dataTransfer.getData('text/plain'));
      const dummyPlacement = this.getClosestBorder(event.clientY) === 'top' ? 'before' : 'after';
      const dropPoint = this.point;
      this.$emit('move', movedPointId, dummyPlacement, dropPoint);
      document.getElementById('dummy')?.remove();
    },
    onDragEnd() {
      document.getElementById('dummy')?.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.point {
  &__container {
    min-height: 60px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s linear;
    cursor: pointer;
    user-select: none;

    &:hover {
      border-radius: 5px;
      background-color: #fff4f4;
      transition: background-color 0.2s linear;

      .point__drag {
        opacity: 1;
        transition: opacity 0.1s linear;
      }
    }

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 35px;
      height: 100%;
      width: 4px;
      background-color: #d40302;
    }

    &_first:before {
      top: 50%;
    }
  }

  &__drag {
    color: #d4030299;
    opacity: 0;
    transition: opacity 0.1s linear;
  }

  &__marker {
    position: absolute;
    top: 50%;
    left: 17px;
    transform: translate(0, -50%);
    border: 4px #d40302 solid;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      font-size: 1.5rem;
      line-height: 1.5rem;
      padding-bottom: 5px;
      font-weight: bold;
    }
  }

  &__content {
    padding: 0 1rem 0 70px;
    height: 100%;
    width: 100%;
  }
}
</style>
