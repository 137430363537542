<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>{{ pageTitle }}</h4>
      <div>
        <b-button
          v-if="componentMode === 'create'"
          type="submit"
          form="placeForm"
          class="mr-2"
          variant="outline-primary"
        >
          Создать
        </b-button>
        <b-button
          v-if="componentMode === 'edit'"
          type="submit"
          form="placeForm"
          class="mr-2"
          variant="outline-primary"
        >
          Сохранить
        </b-button>
        <b-button variant="outline-secondary" @click="cancel">Отмена</b-button>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col cols="5">
        <form @submit.prevent="onSubmit" id="placeForm">
          <b-form-group label="Название" label-class="pb-0">
            <b-form-input required v-model="model.title" autofocus />
          </b-form-group>

          <b-form-group label="Описание" label-class="pb-0">
            <b-form-textarea v-model="model.description" rows="5" />
          </b-form-group>

          <b-form-group label="Автор" label-class="pb-0">
            <b-form-select v-model="model.author_id">
              <b-form-select-option v-for="author in authors" :key="author.id" :value="author.id">
                {{ author.first_name }} {{ author.last_name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </form>
        <div v-if="componentMode === 'edit'">
          <h4 class="mt-3">Изображение</h4>
          <Photo
            v-if="model.image"
            class="image-container"
            :photo="model"
            path="image"
            previewPath="preview_image"
            @delete="onImageDelete"
          />
          <DropBox v-else class="mt-3" @input="onFileSelected" />
        </div>
      </b-col>
      <b-col cols="7">
        <RouteTimeline
          v-if="componentMode === 'edit'"
          class="mt-3"
          :points="model.points"
          :is-busy="updatingPointPosition"
          @delete="deletePoint"
          @edit="editPoint"
          @add="addPoint"
          @move="movePoint"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import DropBox from '@/components/DropBox.vue';
import Photo from '@/components/galery/Photo.vue';
import RouteTimeline from '@/components/routeTimeline/RouteTimeline.vue';

import { Routes, Authors, Images, Place, RoutePoints } from '@/request';

export default {
  name: 'Route',
  components: { DropBox, Photo, RouteTimeline },
  computed: {
    componentMode() {
      return this.$route?.meta?.mode || 'create';
    },
    pageTitle() {
      return this.componentMode === 'edit' ? 'Редактировать маршрут' : 'Создать маршрут';
    },
  },
  data() {
    return {
      model: {
        title: '',
        description: '',
        author_id: null,
        image: '',
        preview_image: '',
        points: [],
      },
      authors: [],
      places: [],
      updatingPointPosition: false,
    };
  },
  beforeMount() {
    this.getAuthors();
    this.getPlaces();
    if (this.componentMode === 'edit') {
      this.getRoute();
    }
  },
  methods: {
    async getAuthors() {
      try {
        const { data } = await Authors.getAuthors({ per_page: 999 });
        this.authors = data;
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async getPlaces() {
      try {
        const { data } = await Place.getPlaces({ per_page: 999 });
        this.places = data;
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async getRoute() {
      try {
        Object.assign(this.model, await Routes.getRoute(this.$route.params.id));
        if (this.model.points?.length) {
          this.model.points.sort((a, b) => a.order_number - b.order_number);
        }
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async createRoute() {
      try {
        const newPlace = await Routes.createRoute(this.model);
        this.$bvToast.toast('Новый маршрут создан', { variant: 'success' });
        await this.$router.push({ name: 'EditRoute', params: { id: newPlace.id } });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async updateRoute() {
      try {
        await Routes.updateRoute(this.$route.params.id, this.model);
        this.$bvToast.toast('Маршрут обновлен', { variant: 'success' });
        await this.$router.push({ name: 'Routes' });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    onSubmit() {
      switch (this.componentMode) {
        case 'create':
          this.createRoute();
          break;
        case 'edit': {
          this.updateRoute();
          break;
        }
        default:
          return;
      }
    },
    cancel() {
      this.$router.push({ name: 'Routes' });
    },
    async onFileSelected(files) {
      if (!files.length) {
        return;
      }

      const formData = new FormData();
      formData.append('image', files[0], files[0].name);

      try {
        await Images.uploadRouteImage(this.$route.params.id, formData);
        await this.getRoute();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async onImageDelete() {
      try {
        await Images.deleteRouteImage(this.$route.params.id);
        await this.getRoute();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async deletePoint(point) {
      try {
        await RoutePoints.deleteRoutePoint(point.id);
        await this.getRoute();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    editPoint(point) {
      this.$router.push({
        name: 'EditRoutePoint',
        params: { id: this.$route.params.id, pointId: point.id },
      });
    },
    addPoint() {
      this.$router.push({ name: 'NewRoutePoint', params: { id: this.$route.params.id } });
    },
    async movePoint(movedPointId, dummyPlacement, dropPoint) {
      const movedPoint = this.model.points.find(({ id }) => id === movedPointId);
      if (!movedPoint) {
        return;
      }

      const dropPointIndex = this.model.points.indexOf(dropPoint);

      const previousPoint =
        dummyPlacement === 'before' ? this.model.points[dropPointIndex - 1] : dropPoint;
      const nextPoint =
        dummyPlacement === 'before' ? dropPoint : this.model.points[dropPointIndex + 1];

      const isPointMoved = previousPoint !== movedPoint && nextPoint !== movedPoint;
      if (!isPointMoved) {
        return;
      }

      const previousPosition = previousPoint?.order_number || 0;
      const nextPosition = nextPoint?.order_number || previousPoint.order_number + 2000;

      const newPointPosition = (previousPosition + nextPosition) / 2;

      const clonePoint = cloneDeep(movedPoint);
      clonePoint.order_number = newPointPosition;

      try {
        this.updatingPointPosition = true;
        await RoutePoints.updateRoutePoint(clonePoint.id, clonePoint);
        await this.getRoute();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      } finally {
        this.updatingPointPosition = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  height: auto;
  max-height: 300px;
  width: 100%;
}
</style>
