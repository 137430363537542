<template>
  <b-overlay
    id="overlay-background"
    :show="isBusy"
    variant="light"
    opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <template #overlay>
      <b-spinner variant="primary" />
    </template>
    <RouteTimelinePoint
      v-for="(point, index) in points"
      :key="index"
      :point="point"
      :index="index"
      @delete="$emit('delete', $event)"
      @edit="$emit('edit', $event)"
      @click="$emit('edit', $event)"
      @move="$emit('move', ...arguments)"
    />
    <RouteTimelineNewPoint :isFirst="!points.length" @click="$emit('add', $event)" />
  </b-overlay>
</template>

<script>
import RouteTimelinePoint from '@/components/routeTimeline/RouteTimelinePoint.vue';
import RouteTimelineNewPoint from '@/components/routeTimeline/RouteTimelineNewPoint.vue';

export default {
  name: 'RouteTimeline',
  components: { RouteTimelinePoint, RouteTimelineNewPoint },
  props: {
    points: { type: Array, default: () => [] },
    isBusy: { type: Boolean, default: false },
  },
  computed: {
    containerClasses() {
      return {
        'route-timeline': true,
        'route-timeline_busy': this.isBusy,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.route-timeline {
  opacity: 1;
  transition: opacity 0.2s linear;

  &_busy {
    opacity: 0.5;
    transition: opacity 0.2s linear;
  }
}
</style>
